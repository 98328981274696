<template>
    <div>
        shop
    </div>
</template>

<script>
export default {
    name: 'ProjectShop',

    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="less" scoped>
div {
    color: rgb(150, 225, 0);
    font-size: 120px;
}
</style>