import vue from 'vue';
import vuex from 'vuex'
import persistedState from 'vuex-persistedstate'
// 引入小仓库的路径
import index1 from './index1';
import elementstorg from '@/Element UI/elementstorg';
vue.use(vuex)
export default new vuex.Store({
    nemespaced: false,
    // state 相当于data，用于存储数据源
    state: {
        num: 1,
        str: 'string',
        bool: true,
        obj: { name: '张三' },
        arr: [1, 2, 3, 4],
        sum: 0,
    },
    // 计算属性===computed
    getters: {
        // 所依赖的数据源发生变化的时候firtName也会发生变化
        firtName(state) {
            return state.obj.name
        }
    },
    // 直接修改state里面的数据源
    mutations: {
        changeSun(state, newVal) {
            state.arr = newVal
        },
        add(state, newData) {
            state.sum += newData
        },
        sub(state, newData) {
            state.sum -= newData
        },
        odd(state, newData) {
            state.sum += newData
        }
    },
    // 异步修改state里面的数据源
    actions: {
        // 声明jie
        modifyDataSourceadd({ commit }, newData) {
            commit('add', newData)
        },
        modifyDataSourcesub({ commit }, newData) {
            commit('sub', newData)
        },
        modifyDataSourceodd({ commit }, newData) {
            commit('odd', newData)
        }
    },
    // 模块化vuex
    modules: {
        index1,
        elementstorg
    },
    // 数据持久化
    plugins: [persistedState()]

})