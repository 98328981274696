export default {
    namespaced: true,// 为当前模块开启独立的命名空间
    state: {
        obj: ['数学', '语文', '前端'],
        num: 0
    },
    getters: {
        changeObject(state, val) {
            return state.num = 'Vue框架'
        }
    },
    mutations: {
        changemath(state, val) {
            console.log(33);
            state.obj[0] = 'math'
        },
        changeChina(state, val) {
            state.obj[1] = val
        }
    },
    atcions: {
        changechinese(context, val) {
            setTimeout(() => {
                context.commit('changeChina', val)
            }, 1000);
        }
    },
    modules: {

    },


}