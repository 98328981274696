import VueRouter from "vue-router";
// VueRouter 路由器
import home from "./home.vue"
import shop from './shop.vue'
const routers = new VueRouter({
    // 区别history路径不带#，比较美观
    // history模式，刷新后会把路径当成接口资源，但是hash模式不会
    // hash路径带#，不美观
    mode: 'history',
    // router 路由
    routes: [
        {
            // path 为' / '代表一进入页面就展示的页面
            path: '/',
            name: 'Element',
            component: () => import('../Element UI/App.vue'),
            // 重定向
            redirect: { name: 'homePage' }
        },
        // 主页面
        {
            path: '/home',
            component: home,
            name: 'home',
            redirect: {
                name: 'user'
            },
            children: [{
                //路由跳转的路径，自己命名 
                path: '/shop',
                // 跳转到的组件（有两种写法）
                /* 1 */  component: shop,
                name: 'shop',
                meta: {
                    title: 'home',
                    isAutr: true
                }
            },
            {
                path: '/user',
                /* 2 */  component: () => import('./user.vue'),
                name: 'user',
                meta: {
                    title: 'home',
                    isAutr: true
                }
            }]

        },
        // {
        //     //路由跳转的路径，自己命名 
        //     path: '/home',
        // // 跳转到的组件（有两种写法）
        // /* 1 */  component: home,
        //     name: 'home'
        // },

        // login页面
        {
            path: '/login',
        /* 2 */  component: () => import('./login.vue'),
            name: 'login',
            meta: {
                title: 'login',
                isAutr: true
            },
            components: {
                default: () =>
                    import('./login.vue'),
                a: () =>
                    import('./shop.vue')
            }
            // 路由独享守卫
            // beforeEnter: (to, from, next) => {
            //     if (to.meta.isAutr) {
            //         next()
            //     } else {
            //         next(false)
            //     }
            // }
        },
        // element ui页面
        {
            path: '/ElementUI',
            component: () => import('../Element UI/App.vue'),
            name: 'ElementUI',
            redirect: {
                name: 'homePage'
            },
            children: [{
                //路由跳转的路径，自己命名 
                path: '/homePage',
                // 跳转到的组件（有两种写法）
                /* 1 */  component: () => import('../Element UI/homePage.vue'),
                name: 'homePage',
                meta: {
                    title: 'homePage',
                    isAutr: true
                }
            },
            {
                path: '/userPage',
                /* 2 */  component: () => import('../Element UI/userPage.vue'),
                name: 'userPage',
                meta: {
                    title: 'userPage',
                    isAutr: true
                }
            },
            {
                path: '/shopPage',
                /* 2 */  component: () => import('../Element UI/shopPage.vue'),
                name: 'shopPage',
                meta: {
                    title: 'shopPage',
                    isAutr: true
                }
            },{
                path: '/page1',
                /* 2 */  component: () => import('../Element UI/page1.vue'),
                name: 'page1',
                meta: {
                    title: 'page1',
                    isAutr: true
                }
            },{
                path: '/page2',
                /* 2 */  component: () => import('../Element UI/page2.vue'),
                name: 'page2',
                meta: {
                    title: 'page2',
                    isAutr: true
                }
            }]
        }
    ]
})
// 路由守卫
// 第一个 前置守卫
// routers.beforeEach((to, from, next) => {
//     console.log('to', to, 'from', from);
//     const userN = JSON.parse(localStorage.getItem('user'))
//     const loggedIn = localStorage.getItem("loggedIn");
//     // console.log(userN);

//     // 放行
//     // next()
//     // 1.
//     if (!userN && to.path !== '/login') {
//         console.log(1, to, from);
//         next({ name: 'login' })
//     } else if (loggedIn && to.path === '/login') {
//         next({ name: 'home' })
//         console.log(2);
//     } else {
//         console.log(3);
//         next()
//     }
//     // next()

//     // 2.
//     // 本地有值
//     // if (loggedIn) {
//     //     // 如果是前往login页面
//     //     if (to.name == 'login') {
//     //         // 那么就直接给他一个固定地址(主页面)
//     //         next({ name: 'home' })
//     //     }
//     //     // 如果不是前往login页面 
//     //     else {
//     //         // 直接放行
//     //         next()
//     //     }
//     // }
//     // // 本地没有值
//     // else {
//     //     // 去login以外的页面
//     //     if (to.name != 'login') {
//     //         // 那么就直接让他恢复到login页面
//     //         next({ name: 'login' })
//     //     }
//     //     // 在login页面就继续放行
//     //     else {
//     //         next()
//     //     }
//     // }

//     // 禁止通行
//     // next(false)
//     // 指定路由
//     // next({ name: '', path: '' })

//     // login页面使用前置守卫控制用户不允许直接进入home页面

// })

// 后置守卫
routers.afterEach((to, from) => {
    // console.log('后置首位的to', to);
    document.title = to.meta.title
})

export default routers