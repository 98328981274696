<template>
    <div>
        <div @click="num++">
        我是全局组件{{ num }}  <!-- mian.js 定义引用全局组件 -->
    </div>
    <button @click="ck">传送</button>
    </div>
    

</template>
<script>
import bus from '../js/bus';

export default {
    name: 'ProjectSon1',

    data() {
        return {
            num: 0
        };
    },

    mounted() {
this.ck()
    },

    methods: {
        ck() {
            bus.$emit('send', this.num)
        }
    },
    components: {
    }
};
</script>